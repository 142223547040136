
<script setup>
    import { computed } from "vue";

    import colors from "mobile.colors.js";

    const props = defineProps({
        "to": {},
        "img": {},
        "ribbon": {},
        "title2": { type: String },
        "delete": { type: Function, required: false },
        "href": { type: String, required: false },
    });

    const elementType = computed(() => {
        if (props.href || !props.to) {
            return "a";
        } else {
            return "router-link";
        }
    });

    function onClick(e) {
        console.log(e);
    }
    
</script>

<template>
    <component :is="elementType" :to="to" :href="href" class="afm-app-card" @click="onClick">
        <div class="flex-shrink-0" v-if="props.ribbon" style="width: 5px; border-radius: 0.5rem 0 0 0.5rem;" :style="{ 'background-color': colors[props.ribbon].background }"></div>
        <div class="flex-grow-1" style="padding: 0.75rem; min-width: 0;">
            <div class="d-flex">
                <div class="flex-grow-1" style="min-width: 0;">
                    <div class="font-n5 uppercase tracking-4 text-muted text-truncate">
                        <slot name="type" />
                    </div>
                    <div class="font-n1 fw-medium">
                        <slot name="title" />
                    </div>
                    <template v-if="title2">
                        <div class="font-n1 fw-medium">
                            {{ title2 }}
                        </div>
                    </template>
                    <template v-if="$slots.description">
                        <div class="font-n2">
                            <slot name="description" />
                        </div>
                    </template>
                    <template v-if="$slots.pills">
                        <div class="d-flex" style="margin-top: 0.25rem; gap: 0.25rem;">
                            <slot name="pills" />
                        </div>
                    </template>
                </div>
                <template v-if="img">
                    <div class="ms-2 flex-shrink-0" v-if="props.img && !props.img.endsWith('null')">
                        <img
                            class="bg-light border"
                            style="width: 3.5rem; height: 3.5rem; border-radius: 999px; object-fit: cover;"
                            :src="props.img"
                        />
                    </div>
                </template>
            </div>
            <div style="margin-top: 0.25rem; margin-bottom: -0.25rem; font-size: 0.8em;" v-if="$slots.bottom">
                <slot name="bottom"></slot>
            </div>
            <slot />
        </div>
        <template v-if="delete">
            <div class="my-2 flex-shrink-0 d-flex justify-content-center align-items-center border-start">
                <a class="d-block text-muted" style="padding: 0.75rem 1.25rem;" @click="props.delete()">
                    <i class="fal fa-times font-4" />
                </a>
            </div>
        </template>
    </component>
</template>

<!--
<style>
    .afm-app-card-pills {
        > div {
            display: inline-block;
            font-size: 0.7em;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            background-color: rgb(90%, 90%, 90%);
            color: black;
            padding: 0.25rem 0.5rem;
            border-radius: 999px;
        }
    }
</style>
-->

<style scoped>
    .afm-app-card {
        display: flex;
        position: relative;

        color: rgb(15%, 15%, 15%);
        background-color: var(--bs-white);

        text-decoration: none;

        border-radius: 0.5rem;
        box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%);
        border: 1px solid var(--bs-gray-300);

        /* padding: 0.75rem; */

        transition: all 200ms ease-in-out;

        &:active {
            background-color: var(--bs-gray-200);
            transition: all 0ms;
        }
    }


    .afm-app-card:before {
        position: absolute;
        inset: 0;
        background-color: rgb(50%, 50%, 50%);
        opacity: 0%;
        transition: all 200ms ease-in-out;
    }
    .afm-app-card:hover:before {
        opacity: 100%;
    }
</style>
